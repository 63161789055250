.layout-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  gap: 20px;
  width: 2173px;
  height: 3726px;
  transform: translate(-50%, 0%) scale(1);
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 50px 80px 0 80px;
  z-index: 9;
}

.header > img {
  height: 460px;
}

.logo img {
  -o-object-fit: contain;
  object-fit: contain;
}

.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  position: relative;
}

.footer {
  position: fixed;
  bottom: 0;
  background: url(/public/images/footerBg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 100%;
  height: 855px;
  display: flex;
  width: 100%;
  z-index: 9999;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 3% 5%;
}

.user-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.user-details-wrapper p {
  font-size: 52px;
  color: #2d327c;
}

.user-id-wrapper {
  background: #2d327c;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 76px;
  letter-spacing: 28px;
  padding: 22px;
  width: 690px;
  border: 1px solid #fff;
}

.qr-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.qr-sm-text {
  font-size: 46.7px;
}

.qr-lg-text {
  font-size: 85.94px;
}

.qr-img-wrapper {
  background: #fff;
  margin-top: 10px;
  border: 1px solid #000;
  padding: 3rem;
  align-self: stretch;
}

.qr-img-wrapper svg {
  width: 434px;
  height: 434px;
}

.bg-text {
  width: 100%;
  background: #2d327c;
  text-align: center;
  padding: 20px 0;
  font-size: 52px;
  margin-top: 24px;
}

@media screen and (max-width: 991px) {
  .header {
    width: 100px;
    padding: 10px 15px 0 15px;
  }

  .header > img {
    width: 100%;
    height: 100%;
  }
}

.landing-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10%;
  width: 100%;
}

.landing-content::after {
  position: absolute;
  content: "";
  background: url(/public/images/blueEffect.png);
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 1500px;
  width: 100%;
  transform: translateY(-30%);
  z-index: 9;
}

.landing-content.game-content::after {
  display: none;
}

.landing-content h2 {
  font-size: 16rem;
  line-height: 16rem;
  background: linear-gradient(360deg, #9bcbeb 34%, #ffffff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.wave-image {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.spark-img {
  width: 124vw;
  position: absolute;
  z-index: -1;
}

.landing-content-text {
  -webkit-animation: animateText 2s ease forwards;
  animation: animateText 2s ease forwards;
  transform: translateY(117%) scale(0.2);
  opacity: 0;
}

@-webkit-keyframes animateText {
  0% {
    transform: translateY(117%) scale(0.2);
    opacity: 1;
  }
  70% {
    transform: translateY(-10%) scale(1.1);
    opacity: 1;
  }
  80% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translateY(-10%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

@keyframes animateText {
  0% {
    transform: translateY(117%) scale(0.2);
    opacity: 1;
  }
  70% {
    transform: translateY(-10%) scale(1.1);
    opacity: 1;
  }
  80% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translateY(-10%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

.timer-wrapper {
  position: fixed;
  top: 0;
  right: 10%;
  padding: 2rem 7rem 6rem;
  background-image: url(/public/images/timerHomeBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.timer-wrapper h2 {
  font-size: 10rem;
  line-height: 10rem;
}

.timer-wrapper h4 {
  font-size: 5.8rem;
  line-height: 5.8rem;
}

.object-for-wash {
  position: absolute;
  top: 1099px;
  z-index: 3;
}

.liquid-detergent-wrapper {
  left: 50%;
  transform: translateX(-33%) translateY(-3%);
}

.machine-position-home {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -743px);
}

.machine-position-home.close-lid-position {
  transform: translate(-51%, -687px);
}

.close-lid-machine {
  position: absolute;
  left: 0;
  transform: translate(1%, -506px) scaleX(92%) scaleY(92.5%);
}

.clean-shirt {
  top: 548px;
}

.clean-shirt-png {
  top: 642px;
}

.spark-behind-shirt-img {
  top: 400px;
  width: 100%;
  z-index: 1;
}

.patel-behind-shirt {
  top: -100px;
}

.headline-clean-shirt {
  top: 0;
}

.creative-text {
  top: 30%;
  right: 10%;
}

.washing-timer-home {
  position: absolute;
  bottom: 1200px;
  right: 250px;
  transform: scale(2);
}
